import React from "react"
import { Link, graphql } from "gatsby"
import PortableText from "@sanity/block-content-to-react"
import urlBuilder from "@sanity/image-url"
// import Img from "gatsby-image"
import Moment from "react-moment"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import FeatureImage from "../components/FeatureImage"
import PageContentContainer from "../components/PageContentContainer"
import BannerContainer from "../components/bannerContainer"
import BannerTitleNew from "../components/BannerTitleNew"
import WaveDivider from "../components/svgs/WaveDivider"
import BlockContentPrimary from "../components/block content/blockContentPrimary"
import ButtonLink from "../components/ButtonLink"
import { FaArrowLeft } from "react-icons/fa"

const BlogPostTemplate = ({ data }) => {
  const { sanityBlogPost: newsData, defaultPostImage } = data
  const date = newsData.publishedDate || newsData._createdAt

  // ALT TEXT DOESN'T WORK
  const imageAltText = newsData?.featureImage?.asset?.altText || ""
  const imageData =
    newsData?.featureImage?.asset?.gatsbyImageData ||
    defaultPostImage?.childImageSharp?.gatsbyImageData

  return (
    <Layout>
      <SEO title={`${newsData.title}`} />
      <PageContentContainer className="blogPost text-primary">
        <BannerContainer
          color="bg-secondary"
          className="flex flex-col  items-center py-20 pt-5"
        >
          <div className="flex w-full">
            <ButtonLink
              className="flex items-center btn mt-5 ml-5 mb-10 pt-2 pb-2 px-8 text-center font-semibold uppercase  text-lg md:text-xl tracking-wide"
              to="/news"
              text={null}
            >
              <FaArrowLeft className="mr-3" />
              <span>All News Posts</span>
            </ButtonLink>
          </div>
          {/* Content Container */}
          <div className="flex flex-col items-center mx-3 md:mx-10 xl:mx-20 max-w-7xl ">
            {/* Post title */}
            {imageData && (
              <div>
                <GatsbyImage
                  image={imageData}
                  className="w-full max-w-3xl mb-10 shadow-card bg-white"
                  alt={imageAltText}
                />
              </div>
            )}
            <BannerTitleNew
              className="mb-5"
              props={{
                divStyle: "h-1/6 w-full place-items-center",

                text: (
                  <h1 className="text-3xl md:text-6xl font-semibold text-center">
                    {newsData.title}
                  </h1>
                ),
              }}
            />
            <div className="text-2xl">{date}</div>
            {/* Post Summary */}
            {/* <p className="bg-primary bg-opacity-10 p-3 rounded my-5 max-w-2xl italic">
              {newsData.summary}
            </p> */}

            {/* <WaveDivider className=" fill-primary  w-48 my-10 " /> */}
            {/* Block Content Container */}
            <div className="my-10 flex justify-center w-full">
              <BlockContentPrimary
                blockData={newsData._rawPostContent}
                className="w-full"
              />
            </div>
          </div>
        </BannerContainer>
      </PageContentContainer>
    </Layout>
  )
}

// "$slug: String!" means that we are pulling in the slug from the context (passed in BlogPostTemplate) and that it must be a string value
// "(slug: { current: { eq: $slug } })" means that the slug from the query needs to match the value from the context, so that we get info for the correct page.
//see video 16 of LUT gatsby/shopify

export const query = graphql`
  query sanityBlogPostQuery($slug: String!) {
    defaultPostImage: file(
      relativePath: { eq: "WINKS Logo - Circle Only.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(height: 200, aspectRatio: 1, placeholder: BLURRED)
      }
    }
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      id
      _createdAt(formatString: "MMMM D, YYYY")
      publishedDate(formatString: "MMMM D, YYYY")
      title
      summary
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      featureImage {
        asset {
          altText
          gatsbyImageData(width: 800, placeholder: BLURRED)
        }
      }
    }
  }
`

export default BlogPostTemplate
